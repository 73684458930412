<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">
      <QueryModule :query-module="queryModule" @queryRes="queryRes" @reset="reset">
        <el-col :span="8">
          <el-form-item label="状态">
            <el-select v-model="queryModule.status" placeholder="请选择">
              <el-option v-for="item in appStatus" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
      </QueryModule>
      <el-table v-loading="loading" :data="data">
        <el-table-column type="expand">
          <template #default="props">
            <div class="div_table">
              <table>
                <tr>
                  <th>规则数量（个）</th>
                  <th>开始时间</th>
                  <th>结束时间</th>
                  <th>任务状态</th>
                  <th>任务进度</th>
                  <th>匹配上结果（条）</th>
                  <th>未匹配结果（条）</th>
                  <th>总条数（条）</th>
                </tr>
                <tr v-for="(item, index) in props.row.taskResponse" :key="index">
                  <td>
                    <span>{{ item.ruleSize }}</span>
                  </td>
                  <td>
                    <span>{{ item.beginTime }}</span>
                  </td>
                  <td>
                    <span>{{ item.endTime }}</span>
                  </td>
                  <td>
                    <span>{{ item.status }}</span>
                  </td>
                  <td>
                    <el-progress :percentage="item.progress * 100" />
                  </td>
                  <td>
                    <span>{{ item.taskResultResponse.matchNum }}</span>
                  </td>
                  <td>
                    <span>{{ item.taskResultResponse.mismatchNum }}</span>
                  </td>
                  <td>
                    <span>{{ item.taskResultResponse.totalNum }}</span>
                  </td>
                </tr>
              </table>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="编号" prop="id" width="300" />
        <el-table-column label="创建人" prop="createBy" />
        <el-table-column label="所属作业">
          <template #default="{ row }"> {{ row.taskResponse[0].workName }}</template>
        </el-table-column>
        <el-table-column label="任务提交时间" prop="submitTime" width="180" />
        <el-table-column label="任务结束时间" prop="finishTime" width="180" />
        <el-table-column label="任务数量（个）" prop="totalTaskNum" width="130" />
        <el-table-column label="成功/失败（个）" prop="taskResult" width="130" />
        <el-table-column label="运行状态" prop="status" />
      </el-table>
      <el-pagination
        v-model:currentPage="pageModule.currentPage"
        :page-size="pageSize"
        :total="pageModule.totalElements"
        background
        layout="total, prev, pager, next, jumper"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb'
import QueryModule from '/src/components/QueryModule'
import { mapGetters } from 'vuex'

export default {
  name: 'TaskMonitor',
  components: {
    BreadCrumb,
    QueryModule,
  },
  data() {
    return {
      loading: true,
      appStatus: [],
      data: [],
      queryModule: {
        status: '',
      },
      pageModule: {
        totalElements: 0,
        currentPage: 1,
      },
    }
  },
  mounted: function () {
    this.fillCheckbox()
    this.fillData()
  },
  computed: {
    ...mapGetters(['pageSize']),
  },
  methods: {
    fillCheckbox() {
      const _this = this

      this.$axios.GET('/api/v1/core/task/application/selected/get_application_status').then(function (res) {
        const data = res

        for (var val in data.data) {
          _this.appStatus.push({ label: val, value: data.data[val] })
        }
      })
    },
    fillData() {
      const _this = this

      this.$axios
        .POST('/api/v1/core/task/application/all', {
          applicationStatus: _this.queryModule.status,
          page: _this.pageModule.currentPage - 1,
          size: _this.pageSize,
        })
        .then(function (res) {
          const data = res

          _this.data = data.data.content
          _this.pageModule.totalElements = data.data.totalElements
          _this.clearLoadingStatus()
        })
    },
    reset() {
      Object.keys(this.queryModule).forEach(key => (this.queryModule[key] = ''))
      this.pageModule.currentPage = 1
      this.fillData()
    },
    queryRes() {
      this.fillData()
    },
    clearLoadingStatus() {
      this.loading = false
    },
    pageChange(currentPage) {
      this.pageModule.currentPage = currentPage
      this.fillData()
    },
  },
}
</script>
<style>
.div_table {
  padding: 0 10px;
  border: 1px solid #ccc;
}

.div_table > table {
  width: 100%;
}
</style>
